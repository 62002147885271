.contact-form .form-control {
  padding: 12px 20px;
  border-radius: 5px;
  transition: all 0.5s ease-in-out;
}

.contact-form .form-control:focus {
  border-color: #df0303;
  box-shadow: none;
}
