.carousel-caption {
  text-align: left !important;
  right: 0 !important;
  bottom: 22% !important;
  animation-name: slideup;
  animation-duration: 2s;
}

.my-nav-link {
  color: #df0303 !important;
  cursor: pointer;
}

.my-nav-link.active {
  color: black !important;
  cursor: pointer;
}

@keyframes slideup {
  0% {
    margin-bottom: -10rem;
  }
  100% {
    bottom: 22%;
  }
}

.theme-border-btn {
  font-size: 16px;
  color: #df0303;
  border: 2px solid #df0303;
  font-weight: 500;
  border-radius: 5px;
  padding: 10px 25px;
  transition: 0.4s;
}

.theme-border-btn:hover {
  color: #fff;
  background: #df0303;
}

/* feature */
.card {
  border-radius: 5px !important;
}

.title-feature {
  height: 20px;
}

.desc-feature {
  height: 160px;
}

/* service find location */
.bg-service-location {
  background-image: url("../../assets/img/bg-coverage.webp");
  /* background-color: #5a575d; */
  background-size: cover;
  background-repeat: no-repeat;
}

.invalid-feedback {
  display: block !important;
}

/* about us */
.about-list li::before {
  content: ">";
  color: #df0303;
  margin-right: 10px;
}

.aboutus-title {
  display: inline;
  border-bottom: 3px solid #df0303;
}

/* product package */
.my-nav-product {
  cursor: pointer;
}

.my-nav-product .nav-link {
  color: black;
}

.my-nav-product .nav-link:hover {
  color: black;
  font-weight: bold;
}

.my-nav-product .nav-link.active {
  background-image: linear-gradient(45deg, #ed1d24, #3b54a5 80%);
  text-align: center;
  transition: 0.5s;
  background-size: 200% auto;
  color: white;
  box-shadow: 0 0 20px #eee;
  border-radius: 10px;
  display: block;
}

.product-item {
  text-align: center;
  margin-bottom: 25px;
  border-radius: 5px;
  position: relative;
  padding: 10px;
  transition: all 0.5s ease-in-out;
  z-index: 1;
}

.product-class {
  width: 100px;
  height: 100px;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  margin-top: 20px;
  border: 4px solid #df0303;
  font-size: 40px;
  color: #df0303;
  font-weight: bold;
}

.product-content {
  height: 80px;
}

.nav-pills a,
.nav-tabs a {
  cursor: pointer;
}

.theme-btn {
  display: inline-block;
  vertical-align: top;
  font-size: 16px;
  color: #ffffff;
  font-weight: 500;
  padding: 10px 22px;
  border-radius: 5px;
  transition: all 0.5s;
  text-transform: capitalize;
  position: relative;
  cursor: pointer;
  text-align: center;
  border: 2px solid white;
  background: #df0303;
  box-shadow: 0 3px 24px rgb(0 0 0 / 10%);
}

.theme-btn-disabled {
  display: inline-block;
  vertical-align: top;
  font-size: 16px;
  color: gray;
  font-weight: 500;
  padding: 10px 22px;
  border-radius: 5px;
  transition: all 0.5s;
  text-transform: capitalize;
  position: relative;
  cursor: pointer;
  text-align: center;
  border: 2px solid white;
  background: #ccc;
  box-shadow: 0 3px 24px rgb(0 0 0 / 10%);
}

.theme-btn:hover {
  background: white;
  color: #df0303;
  border: 2px solid #df0303;
  font-weight: bold;
}

.theme-btn-blue {
  display: inline-block;
  vertical-align: top;
  font-size: 16px;
  color: #ffffff;
  font-weight: 500;
  padding: 10px 22px;
  border-radius: 5px;
  transition: all 0.5s;
  text-transform: capitalize;
  position: relative;
  cursor: pointer;
  text-align: center;
  border: 2px solid white;
  background: #07193d;
  box-shadow: 0 3px 24px rgb(0 0 0 / 10%);
}

.theme-btn-blue:hover {
  background: white;
  color: #07193d;
  border: 2px solid #07193d;
}

/* services */
.service-item {
  position: relative;
  padding: 50px 40px;
  margin-bottom: 25px;
  background: #fff;
  box-shadow: 0px 18px 13px rgb(234 240 250 / 50%);
  overflow: hidden;
  transition: all 0.5s ease-in-out;
  z-index: 1;
}

.service-item::before {
  content: "";
  position: absolute;
  left: 40px;
  bottom: 0;
  height: 3px;
  width: calc(100% - 80px);
  background: #df0303;
}

.service-button .service-btn {
  color: #df0303;
  background: #eff1f5;
  padding: 10px 25px;
  border-radius: 5px;
  transition: all 0.5s ease-in-out;
}

.service-btn:hover {
  color: white;
  background-color: #df0303;
}

/* testimoni */
.bg-testimonial {
  background-image: url("../../assets/img/testimonial.webp");
  /* background-color: #ffffff; */
  background-size: cover;
  /* color: black; */
}

.testimoni-item {
  background: white;
  border-radius: 20px;
  position: relative;
}

.testimoni-message {
  height: 160px;
}

.testimoni-item img {
  width: 30px !important;
  height: 30px;
}

.testimoni-profile img {
  width: 70px !important;
  height: 70px !important;
  border-radius: 50%;
}

/* belum clear*/
.event {
  position: fixed;
  left: 50px;
  top: 100px;
  z-index: 1000;
  animation-name: ramadhan;
  animation-duration: 4s;
  /* animation-iteration-count: infinite; */
}

@keyframes ramadhan {
  0% {
    top: -150px;
  }
  50% {
    top: -75px;
  }
  100% {
    top: 100px;
  }
}

.move-to-right-appear {
  opacity: 0;
}

.move-to-right-appear-active {
  opacity: 1;
  transition: opacity 1s;
}

.move-to-right-enter {
  opacity: 0;
  animation: movetoright 1s;
}

.move-to-right-enter-active {
  opacity: 1;
  transition: opacity 1s;
}

.move-to-right-enter-done {
  opacity: 1;
}

.move-to-right-exit {
  opacity: 1;
}

.move-to-right-exit-active {
  opacity: 1;
}

.move-to-right-exit-done {
  opacity: 1;
}

@keyframes movetoright {
  from {
    transform: translateX(-100px);
  }
  to {
    transform: translateX(0px);
  }
}
