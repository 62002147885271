.nav-link:hover::before {
  width: 40px;
}

.nav-link::before {
  content: "";
  position: absolute;
  width: 0px;
  transition: 0.5s;
  height: 3px;
  bottom: 0;
  border-radius: 5px;
  background-image: linear-gradient(to right, #f03, #08f);
}

.nav-link {
  position: relative;
}

.nav-link.active:hover::before {
  width: 0px;
}

.my-navbar {
  background-color: white !important;
}

.my-navbar.fixed-top {
  animation: slide-down 0.7s;
}

@keyframes slide-down {
  0% {
    transform: translateY(-100%);
  }

  100% {
    transform: translateY(0);
  }
}

@media only screen and (max-width: 800px) {
  .web-info {
    display: none;
  }
}

.box {
  --border-size: 3px;
  --border-angle: 0turn;
  border-radius: 5px;
  background-image: conic-gradient(
      from var(--border-angle),
      rgb(255, 255, 255) 0%,
      rgb(255, 255, 255) 0%,
      rgb(255, 255, 255) 0%
    ),
    conic-gradient(from var(--border-angle), transparent 20%, #08f, #f03);
  background-size: calc(100% - (var(--border-size) * 2))
      calc(100% - (var(--border-size) * 2)),
    cover;
  background-position: center center;
  background-repeat: no-repeat;

  animation: bg-spin 3s linear infinite;
}

@keyframes bg-spin {
  to {
    --border-angle: 1turn;
  }
}

@property --border-angle {
  syntax: "<angle>";
  inherits: true;
  initial-value: 0turn;
}
