/* this is general css */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

a {
  text-decoration: none !important;
}

ul {
  padding-left: 0px !important;
}

.bg-base {
  background-color: #df0303;
}

.bg-second {
  background-color: #f5f7fa;
}

.bg-fourth {
  background-color: #fff;
}

.bg-third {
  background-color: #0b2048;
}

.bg-layer {
  background-color: rgba(0, 0, 0, 0.6);
}

.bg-copyright {
  background-color: #ffffff;
}

.bg-white {
  background-color: white !important;
}

.bg-thumb {
  background-image: url("./assets/img/thumbpage.webp");
  height: 300px;
  background-repeat: no-repeat;
  background-size: cover;
}

.t-white {
  color: white;
}

.t-black {
  color: black !important;
}

.t-red {
  color: #df0303;
}

.t-gray {
  color: rgb(141, 141, 141);
}

.t-yellow {
  color: #fff600;
}

.fs-7 {
  font-size: 14px;
}

.fs-10 {
  font-size: 10px;
}

.fs-20 {
  font-size: 20px;
}

.fs-32 {
  font-size: 32px;
}

.borad-five {
  border-radius: 5px;
}

#scroll-top {
  display: none;
  position: fixed;
  bottom: 25px;
  left: 10px;
  z-index: 99;
  font-size: 20px;
  border: none;
  outline: none;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
  width: 45px;
  height: 45px;
  line-height: 45px;
  text-align: center;
  z-index: 1;
}

#scroll-top img {
  border-radius: 50%;
}

/* footer */
.sub-footer h3,
.sub-footer h5 {
  border-bottom: 2px solid white;
  display: inline;
}

/* forget password */
.forget-password .form-control {
  padding: 12px 20px;
  border-radius: 5px;
  transition: all 0.5s ease-in-out;
}

.forget-password .form-control:focus {
  border-color: #df0303;
  box-shadow: none;
}

/* scroll bar css */
/* width */
::-webkit-scrollbar {
  width: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: linear-gradient(#df0303, yellow);
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: linear-gradient(yellow, #df0303);
}

.gradient-subtitle {
  font-size: 40px;
  font-weight: 800;
  background: -webkit-linear-gradient(45deg, #ed1d24, #3b54a5 80%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin: 0;
}

.tc-pointer {
  cursor: pointer;
}

.divider-dashed {
  border-top: 1px dashed gray;
}

/* footer */
.my-socmed a {
  color: white;
}

.my-socmed a:hover {
  color: #07193d;
}

/* CSS */
.button-54 {
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  letter-spacing: 2px;
  text-decoration: none;
  /* text-transform: uppercase; */
  color: #000;
  cursor: pointer;
  border: 3px solid;
  padding: 0.25em 0.5em;
  box-shadow: 1px 1px 0px 0px, 2px 2px 0px 0px, 3px 3px 0px 0px, 4px 4px 0px 0px,
    5px 5px 0px 0px;
  position: relative;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.button-54:active {
  box-shadow: 0px 0px 0px 0px;
  top: 5px;
  left: 5px;
}

@media (min-width: 768px) {
  .button-54 {
    padding: 0.25em 0.75em;
  }
}

/* CSS */
.button-85,
.button-85-disabled {
  padding: 0.6em 2em;
  border: none;
  outline: none;
  color: rgb(255, 255, 255);
  background: #111;
  cursor: pointer;
  position: relative;
  z-index: 0;
  border-radius: 10px;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.button-85:before {
  background: linear-gradient(
    45deg,
    #ff0000,
    #ff7300,
    #fffb00,
    #48ff00,
    #00ffd5,
    #002bff,
    #7a00ff,
    #ff00c8,
    #ff0000
  );
}

.button-85:before,
.button-85-disabled:before {
  content: "";
  position: absolute;
  top: -2px;
  left: -2px;
  background-size: 400%;
  z-index: -1;
  filter: blur(5px);
  -webkit-filter: blur(5px);
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  animation: glowing-button-85 20s linear infinite;
  transition: opacity 0.3s ease-in-out;
  border-radius: 10px;
}

@keyframes glowing-button-85 {
  0% {
    background-position: 0 0;
  }
  50% {
    background-position: 400% 0;
  }
  100% {
    background-position: 0 0;
  }
}

.button-85:after,
.button-85-disabled:after {
  z-index: -1;
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: #222;
  left: 0;
  top: 0;
  border-radius: 10px;
}
